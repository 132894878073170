import { sendEvent } from '@utils/core/track';
import AcComponentIndex from 'ac-components/index';
import { COUNTRYCODE_LANG_MAP, COUNTRY_CONFIG } from 'constants/country';
import {
	AC_PATH,
	AGREEMENT_POLICY,
	AGREEMENT_PRIVACY,
	AGREEMENT_USER,
	APPLY_SUCCESS,
	APPLY_TUTOR,
	COUNTRY_HOMEPAGE,
	EN_BECOME_TUTORS,
	EN_DOWNLOAD,
	EN_FOR_PARENTS,
	EN_HOMEPAGE,
	CN_HOMEPAGE,
	EN_HOW_WORK,
	EN_JOIN_US,
	EN_OUR_TUTORS,
	EN_RECRUIT,
	EN_RECRUIT_DETAIL,
	EN_SUBJECTS,
	EN_TEAMS_CAREERS,
	FAQ,
	FALLING_PAGE,
	BOOKING_PAGE,
	RESERVATION_PAGE,
	SCHOOL_PAGE,
	TUTOR_PAGE,
	SUCCESS_PAGE,
	ABROAD_WEBSITE,
	USER_AGREEMENT,
	PRIVACY_POLICY,
	HAPPY_KIDS_ES,
	HAPPY_KIDS_EN,
	INVITE_FRIENDS,
	LOGIN,
	MAIN_HOME,
	ONE_ON_ONE_ES,
	ONE_ON_ONE_EN,
	PARTNER,
	PERSONAL,
	PRICES,
	STEP_APPLY_TUTOR,
	TINT_APPLY_TUTOR,
	TUTORS,
	TEMPDOWNLOAD,
	DY_WRITE_OFF
} from 'constants/url';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import PageLoad from './pageload';
export interface RouterProps {
	isShowMenu?: boolean; //是否显示菜单，默认显示
	path: string | string[]; //路由路径，可以是字符串或者数组
	component: React.FC<RouteComponentProps<{}>> | React.ComponentClass<{}>; //路由组件，可以是组件或者组件的实例
	exact?: boolean; //是否严格匹配，默认为true
	isShowFooter?: boolean; //是否显示底部，默认显示
	isShowHeader?: boolean; //是否显示头部，默认显示
	isShow?: boolean; //是否显示页面，默认显示，如果为false，则跳转到首页
	title?: string; // 标题
}

const __isDEV__ = process.env.NODE_ENV === 'development';

// 路由配置
export const routerConfig: RouterProps[] = [
	{
		path: [MAIN_HOME, '/main-home'],
		component: PageLoad(import(/* webpackChunkName: "page-home" */ 'pages/offical/home/main'), 'EAG_48db39991736ae9b'),
	},
	{
		path: [ONE_ON_ONE_ES, ONE_ON_ONE_EN],
		component: PageLoad(import(/* webpackChunkName: "page-1on1" */ 'pages/offical/1on1/main'), 'EAG_fc81d7092d1d0d9d'),
	},
	{
		path: [HAPPY_KIDS_ES, HAPPY_KIDS_EN],
		component: PageLoad(import(/* webpackChunkName: "page-happy-kids" */ 'pages/offical/happy-kids/main'), 'EAG_54c987e29bb45ac0'),
	},
	{
		path: TUTORS,
		component: PageLoad(import(/* webpackChunkName: "page-our-tutors" */ 'pages/offical/tutors/main')),
	},
	{
		path: FAQ,
		component: PageLoad(import(/* webpackChunkName: "page-faq" */ 'pages/offical/faq/main'), 'EAF_d8a6c65b46a0bea9'),
	},
	{
		path: [PERSONAL, '/personal'],
		component: PageLoad(import(/* webpackChunkName: "page-personal" */ 'pages/offical/personal/main')),
	},
	{
		path: [INVITE_FRIENDS, '/invite-friends'],
		component: PageLoad(import(/* webpackChunkName: "page-invite-friends" */ 'pages/invite-friends/main')),
	},
	{
		path: [AGREEMENT_USER, '/agreement/user'],
		component: PageLoad(import(/* webpackChunkName: "page-agreement-user" */ 'pages/landing/agreement/user')),
		isShowMenu: false,
		isShowFooter: false,
	},
	{
		path: [AGREEMENT_POLICY, '/agreement/policy'],
		component: PageLoad(import(/* webpackChunkName: "page-agreement-policy" */ 'pages/landing/agreement/policy')),
		isShowMenu: false,
		isShowFooter: false,
	},
	{
		path: [AGREEMENT_PRIVACY, '/agreement/privacy'],
		component: PageLoad(import(/* webpackChunkName: "page-agreement-policy" */ 'pages/landing/agreement/privacy')),
		isShowMenu: false,
		isShowFooter: false,
	},
	{
		path: LOGIN,
		component: PageLoad(import(/* webpackChunkName: "page-login" */ 'pages/offical/login/main'), 'EAF_9d9c551593d1cb3e'),
		isShowFooter: false,
	},
	{
		path: [APPLY_TUTOR, TINT_APPLY_TUTOR, STEP_APPLY_TUTOR],
		component: PageLoad(import(/* webpackChunkName: "page-apply-tutor" */ 'pages/landing/apply-tutor/main'), 'EAF_1459eb2aceecab14'),
		isShowHeader: false,
		isShowFooter: false,
	},
	{
		path: [APPLY_SUCCESS, '/apply-tutor/success'],
		component: PageLoad(import(/* webpackChunkName: "page-apply-success" */ 'pages/landing/apply-success/main'), (props) => {
			let eventId = 'en_adPage0802_SuccessPageView'; // 从六国落地页跳转来
			if (props.location?.state?.nation === COUNTRYCODE_LANG_MAP.EN) {
				// 从英国官网跳转来
				eventId = 'en_offWebsite0811_SuccessPageView';
			}
			if (props.location?.state?.step === COUNTRYCODE_LANG_MAP.UK) {
				// 从英国落地二跳页跳转来
				eventId = 'EAF_b0b8be1a5eb69f48';
			}
			if (props.location?.state?.tint === COUNTRYCODE_LANG_MAP.UK) {
				// 从英国落地动态页跳转来
				eventId = 'EAF_909c753e9d3ac827';
			}
			sendEvent({ eventId });
		}),
	},
	{
		path: EN_HOMEPAGE,
		component: PageLoad(import(/* webpackChunkName: "page-home-page" */ 'pages/en/homepage/main'), 'en_offWebsite0811_PageView'),
	},
	{
		path: CN_HOMEPAGE,
		component: PageLoad(import(/* webpackChunkName: "page-home-page" */ 'pages/cn/homepage/main'), 'en_offWebsite0811_PageView'),
		isShowFooter: false,
	},
	{
		path: EN_RECRUIT,
		component: PageLoad(import(/* webpackChunkName: "page-recruit" */ 'pages/en/en-recruit/main'), 'joblist'),
	},
	{
		path: EN_RECRUIT_DETAIL,
		component: PageLoad(import(/* webpackChunkName: "page-recruit-detail" */ 'pages/en/en-recruit/detail'), 'jobdetail'),
	},
	{
		path: EN_DOWNLOAD,
		component: PageLoad(import(/* webpackChunkName: "page-recruit-detail" */ 'pages/en/download/main')),
	},
	{
		path: EN_BECOME_TUTORS,
		component: PageLoad(import(/* webpackChunkName: "page-become-tutors" */ 'pages/en/become-tutors/main')),
	},
	{
		path: EN_TEAMS_CAREERS,
		component: PageLoad(import(/* webpackChunkName: "page-teams-careers" */ 'pages/en/teams-careers/main')),
	},
	{
		path: EN_FOR_PARENTS,
		component: PageLoad(import(/* webpackChunkName: "page-for-parents" */ 'pages/en/for-parents/main')),
	},
	{
		path: EN_OUR_TUTORS,
		component: PageLoad(import(/* webpackChunkName: "page-our-tutors" */ 'pages/en/our-tutors/main')),
		isShow: false,
	},
	{
		path: EN_HOW_WORK,
		component: PageLoad(import(/* webpackChunkName: "page-how-works" */ 'pages/en/how-works/main')),
		isShow: false,
	},
	{
		path: PRICES,
		component: PageLoad(import(/* webpackChunkName: "page-prices" */ 'pages/offical/prices/main'), 'ACW_pageview_prices'),
	},
	{
		path: PARTNER,
		component: PageLoad(import(/* webpackChunkName: "page-partner" */ 'pages/offical/partner/main'), 'ACW_pageview_prices'),
	},
	{
		path: EN_JOIN_US,
		component: PageLoad(import(/* webpackChunkName: "page-join-us" */ 'pages/en/join-us/main')),
	},
	{
		path: EN_SUBJECTS,
		component: PageLoad(import(/* webpackChunkName: "page-subjects" */ 'pages/en/subjects/main')),
		isShow: false,
	},
	//组件开发测试页面
	{
		path: AC_PATH,
		component: AcComponentIndex,
		isShowHeader: false,
		isShowFooter: false,
		isShow: __isDEV__,
	},
	//动态通配页面，用于6国落地页
	{
		path: [...COUNTRY_CONFIG.map((item) => '/' + item.countryCode), ...COUNTRY_CONFIG.map((item) => '/' + item.countryCode + '/')],
		component: PageLoad(import(/* webpackChunkName: "page-homepage" */ 'pages/offical/homepage/main')),
	},
	{
		path: FALLING_PAGE,
		component: PageLoad(import(/* webpackChunkName: "landing-page" */ 'pages/offical/landing-page/main')),
		isShowFooter: false,
	},
	{
		path: BOOKING_PAGE,
		component: PageLoad(import(/* webpackChunkName: "booking-page" */ 'pages/offical/booking-page/main')),
		isShowFooter: false,
		isShowMenu: false
	},
	{
		path: RESERVATION_PAGE,
		component: PageLoad(import(/* webpackChunkName: "reservation-page" */ 'pages/offical/booking-page/reservation')),
		isShowFooter: false,
		isShowMenu: false,
		isShowHeader: false,
	},
	{
		path: SCHOOL_PAGE,
		component: PageLoad(import(/* webpackChunkName: "school-page" */ 'pages/offical/booking-page/school')),
		isShowFooter: false,
		isShowMenu: false,
		isShowHeader: false,
	},
	{
		path: TUTOR_PAGE,
		component: PageLoad(import(/* webpackChunkName: "tutor-page" */ 'pages/offical/booking-page/tutor')),
		isShowFooter: false,
		isShowMenu: false,
		isShowHeader: false,
	},
	{
		path: SUCCESS_PAGE,
		component: PageLoad(import(/* webpackChunkName: "success-page" */ 'pages/offical/booking-page/success')),
		isShowFooter: false,
		isShowMenu: false
	},
	{
		path: ABROAD_WEBSITE,
		component: PageLoad(import(/* webpackChunkName: "abroad-website" */ 'pages/offical/abroad-website/index')),
		isShowFooter: false,
		isShowMenu: false
	},
	{
		path: USER_AGREEMENT,
		component: PageLoad(import(/* webpackChunkName: "user-agreement" */ 'pages/offical/abroad-website/user-agreement')),
		isShowFooter: false,
		isShowMenu: false
	},
	{
		path: PRIVACY_POLICY,
		component: PageLoad(import(/* webpackChunkName: "privacy-policy" */ 'pages/offical/abroad-website/privacy-policy')),
		isShowFooter: false,
		isShowMenu: false
	},
	{
		path: TEMPDOWNLOAD,
		component: PageLoad(import(/* webpackChunkName: "page-for-parents" */ 'pages/tempdownload')),
		isShowMenu: false,
		isShowFooter: false,
	},
	{
		path: DY_WRITE_OFF,
		component: PageLoad(import(/* webpackChunkName: "page-home-page" */ 'pages/activity/writeOff')),
		isShowMenu: false,
		isShowFooter: false,
		isShowHeader: false,
	},
];
