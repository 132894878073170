/**************** 7国落地 **************/
export const APPLY_TUTOR = '/:countryCode/apply-tutor'; // 预约
export const APPLY_SUCCESS = '/:countryCode/apply-tutor/success'; // 预约
export const AGREEMENT_USER = '/:countryCode/agreement/user'; // 用户协议
export const AGREEMENT_POLICY = '/:countryCode/agreement/policy'; // 用户隐私协议
export const AGREEMENT_PRIVACY = '/:countryCode/agreement/privacy'; // 用户隐私协议

/**************** 英国落地页二跳页 **************/
export const STEP_APPLY_TUTOR = '/uk/apply-tutor/step'; // mode: step,二跳页; tint,动效页
export const TINT_APPLY_TUTOR = '/uk/apply-tutor/tint';

/**************** 英语/西语 页面路由别名 **************/
export const ONE_ON_ONE_ES = '/es/tutoria1a1'; // 1对1
export const ONE_ON_ONE_EN = '/en/1on1tutoring'; // 1对1

export const HAPPY_KIDS_ES = '/es/happy-kids'; // happy kids
export const HAPPY_KIDS_EN = '/en/kidslearning'; // happy kids

/**************** 英国官网 **************/
export const LOGIN = '/login'; // 欧美官网登录页
export const EN_HOMEPAGE = '/en'; // 英国官网
export const CN_HOMEPAGE = '/cn'; // 英国官网在国内使用

export const EN_RECRUIT = '/:countryCode/recruit'; // 招聘
export const EN_RECRUIT_DETAIL = '/:countryCode/recruit/detail/:id'; // 招聘详情
export const EN_BECOME_TUTORS = '/:countryCode/become-tutors'; // 成为老师
export const EN_DOWNLOAD = '/:countryCode/download'; // 下载
export const EN_TEAMS_CAREERS = '/:countryCode/teams-careers'; // 团队和里程碑
export const EN_FOR_PARENTS = '/:countryCode/for-parents'; // 家长知晓
export const EN_OUR_TUTORS = '/:countryCode/our-tutors'; // 我们老师
export const EN_HOW_WORK = '/:countryCode/how-work'; // 如何工作
export const EN_JOIN_US = '/:countryCode/join-us'; // 加入我们
export const EN_SUBJECTS = '/:countryCode/subjects'; // 科目
export const TUTORS = '/:countryCode/tutors'; // tutors
export const PRICES = '/:countryCode/prices'; // prices
export const PARTNER = '/:countryCode/partner'; // partner

export const COUNTRY_HOMEPAGE = '/:countryCode';  // 六国官网

export const INVITE_FRIENDS = '/:countryCode/invite-friends' // 转介绍页面

export const PERSONAL = '/:countryCode/personal/:activeId' // 个人中心

export const MAIN_HOME = '/:countryCode/main-home'; // 官网首页（多国）
export const FAQ = '/:countryCode/faq'
export const FALLING_PAGE = '/:countryCode/falling-page'; // UK 落地页
export const BOOKING_PAGE = '/:countryCode/booking-page'; // 预定页面
export const RESERVATION_PAGE = '/:countryCode/reservation-page';  // 第二步
export const SCHOOL_PAGE = '/:countryCode/school-page';
export const TUTOR_PAGE = '/:countryCode/tutor-page';
export const SUCCESS_PAGE = '/:countryCode/success-page';
export const ABROAD_WEBSITE = '/:countryCode/abroad-website'; // 留学官网
export const USER_AGREEMENT = '/:countryCode/user-agreement'; // 用户协议
export const PRIVACY_POLICY = '/:countryCode/privacy-policy'; // 隐私政策

export const AC_PATH = '/ac-components'

export const TEMPDOWNLOAD = '/tempdownload'; // 临时下载页面

export const DY_WRITE_OFF = '/s/6DzjAm'  //抖音核销

/**************** 加SEO **************/
export const seoAllPath = [
	ONE_ON_ONE_ES,
	ONE_ON_ONE_EN,
	HAPPY_KIDS_ES,
	HAPPY_KIDS_EN,
	'/en/tutors',
	'/es/tutors',
	'/main-home',
];
